.region-content {
  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border: 3px solid $border-color;
  }

  tbody {
    border: 0;
  }

  td, th {
    padding: .5em;
    border: 1px solid $border-color;
  }

  blockquote {
    background-color: #ffe6c1;
    font-size: 15px;
  }

  iframe {
    width: 100%;
    display: block;
    pointer-events: none;
    position: relative; /* IE needs a position other than static */
    &.clicked {
      pointer-events: auto;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .field-name-field-alcim {
    font-weight: bold;
    font-size: 1.2em;
    text-align: right;
  } 
}

@media only screen and (max-width: 767px) {
  .sidebar-container {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  .content-area > *:first-child {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 10px solid #eee;
  }
}