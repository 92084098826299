div.block-menu {
  h2 {
    font-size: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 0.25em;
    padding-left: 5px;
    margin-top: 0;
  }

  ul {
    padding: 0;
    margin-bottom: 1em;

    li {
      list-style: none;
      padding-left: 5px;
      margin-left: 0;

      a {
        color: #000;

        &.active {
          font-weight: 600;
        }
      }
    }
  }
}