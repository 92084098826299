#footer-wrap {
  margin-top: 40px;
}

#copyright-wrap {
  border-top: 1px solid black;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
  font-weight: 600;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
