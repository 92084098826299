$border-color: #cdcdcd;

div.block-user {
  margin-bottom: 1em;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 0.25em;
    padding-left: 5px;
    margin-top: 0;
  }

  form {
    label {
      display: none;
    }
  }

  input[type="text"],
  input[type="password"] {
    width: 200px;
    height: 30px;
    border: 1px solid $border-color;
    padding: 0 30px 0 5px;
    margin-right: 5px;
  }

  input[type="submit"] {
    color: #5f3900;
    background-color: #e0e0e0;
    border: 0;
    height: 30px;
    padding: 6px;
  }

  ul {
    padding: 0;
    margin: 1em 0;

    li {
      list-style: none;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0.5em;
      display: inline-block;

      a {
        color: #000;
      }
    }
  }
}
