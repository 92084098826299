$wrapper: '#wrapper';
$content-wrapper: '#content-wrapper';
$sidebar-wrapper: '#sidebar-wrapper';
$toggled: '.toggled';
$sidebar-nav: '.menu';
$font-color: #333;
$background-color: #f7f7f9;

body {
  overflow-x: hidden;
}

/* Toggle Styles */

#{$wrapper} {
  padding-right: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &#{$toggled} {
    padding-right: 250px;

    #{$content-wrapper} {
      position: absolute;
      margin-left: -250px;
    }

    #{$sidebar-wrapper} {
      width: 250px;
      overflow: hidden;
    }
  }
}

#{$sidebar-wrapper} {
  z-index: 1000;
  position: fixed;
  right: 0;
  width: 0;
  height: 100%;
  margin-right: 0;
  overflow-y: auto;
  background: $background-color;
  border-left: 1px solid #f8f8f8;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#{$content-wrapper} {
  width: 100%;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Sidebar Styles */

#{$sidebar-wrapper} #{$sidebar-nav} {
  position: absolute;
  top: 0;
  width: 249px;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    text-indent: 20px;
    line-height: 40px;

    a {
      display: block;
      text-decoration: none;
      color: #777;

      &:hover {
        text-decoration: none;
        background-color: #eee;
        color: #555;
      }

      &.active,
      &:active,
      &:focus {
        text-decoration: none;
        background-color: #ddd;
        color: #444;
      }
    }
  }
}


// button
.toggled {
  div#header-wrap {
    #site-mobile-nav-button {
      right: 255px;
    }
  }
}

