$border-color: #cdcdcd;

div.block-search {
  margin-bottom: 1em;

  div.form-item {
    margin: 0;
    position: relative;

    &.form-item-search-block-form {
      width: 100%;
      max-width: 300px;
    }
  }

  input[type="text"] {
    width: 100%;
    height: 30px;
    border: 1px solid $border-color;
    padding: 0 30px 0 5px;
    margin-right: 5px;
  }

  input[type="submit"] {
    color: #5f3900;
    background-color: #e0e0e0;
    border: 0;
    height: 30px;
    padding: 6px;
  }

  .container-inline div, .container-inline label {
    display: inline-block;
  }

  .container-inline .form-actions, .container-inline.form-actions {
    display: none;
  }

  span.field-prefix {
    position: absolute;
    right: 0.5em;
    top: 0;
    bottom: 0;
    display: block;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
