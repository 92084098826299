$orange: #ff9900;
$gray: #eaeaea;
$black: #000000;
$white: #ffffff;

$header-top: #eaf3ff;
$header-bottom: $white;

$after-header-top: #5f3900;
$after-header-bottom: #f2f2f2;

div#header-wrap {
  border-bottom: 3px solid black;
  background: linear-gradient($header-top, $header-bottom);
  margin-bottom: 50px;

  &:after {
    position: absolute;
    width: 100%;
    padding-top: 40px;
    content: '';
    background: linear-gradient($after-header-top, $after-header-bottom);
  }

  header#header {
    margin-top: 25px;

    & > div {
      float: none;
      display: table-cell;

      &#site-mobile-nav-button {
        vertical-align: top;
      }

      &:not(#site-mobile-nav-button) {
        vertical-align: bottom;
      }
    }
  }

  #site-navigation-wrap {
    ul, ol {
      margin: 0;
      padding: 0;

      li.leaf {
        list-style: none none;
        display: inline-block;
        padding: 0;

        &.last {
          margin: 10px 0 0 0;
        }

        &:not(.last) {
          margin: 10px 10px 0 0;
        }

        a {
          padding: 18px 25px;
          margin: 0;
          display: inline-block;
          color: $black;
          position: relative;
          font-weight: 600;

          &.active {
            background-color: $orange;
          }

          &:not(.active) {
            background-color: $gray;
          }

          &:hover {
            text-decoration: none;
          }

          &:hover:not(.active) {
            color: $orange;
          }

          &:before {
            content: '';
            padding: 6px 14px 0 0;
            position: absolute;
            top: -6px;
            left: 0;
            right: 0;
          }

          &.active:before {
            background: url(../img/house-top-orange.png) no-repeat center;
          }

          &:not(.active):before {
            background: url(../img/house-top-gray.png) no-repeat center;
          }
        }
      }
    }
  }

  #site-mobile-nav-button {
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 100;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;


    img {
      cursor: pointer;
    }

    ul {
      li.leaf {
        list-style: none none;
      }
    }
  }
}

@media (max-width: 1199px) {
  div#logo {
    text-align: center;
  }
}