$border-color: #aaa;

body.front {
  .site-content {

    h1.page-title {
      display: none;
    }

    h2, h3 {
      text-align: center;
    }
  }

}

@media (min-width: 768px) {
  .content-area {
    #sidebar {
      float: right;
    }
  }
}